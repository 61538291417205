
import Vue from 'vue';
import Loader from '@/components/ui/Loader.vue';
import { UUID } from '@/domains/common';
import * as LxpAssignmentService from '@/services/api/lxp-assignment';

/**
 * Компонент запуска назначенной программы
 */
export default Vue.extend({
  name: 'CourseStart',
  components: {
    Loader,
  },
  inject: ['Names'],
  computed: {
    assignmentId(): number {
      return parseInt(this.$route.params.assignmentId, 10);
    },
    catalogueTrackId(): UUID {
      return this.$route.params.catalogueTrackId;
    },
  },
  async mounted() {
    await this.runAssignment();
  },
  methods: {
    /**
     * Запускает программу по ее id и по id назначения
     */
    async runAssignment() {
      try {
        const { playerSessionId } = await LxpAssignmentService.userAssignmentRun({
          assignmentUserId: this.assignmentId,
          data: {
            catalogueAtomMainId: this.catalogueTrackId,
          },
        });

        await this.goToAssignmentTrack(playerSessionId);
      } catch (e: any) {
        console.error(e);
        await this.$router.replace({
          name: this.Names.R_APP_LEARNING_TRACKS,
        });
        this.$di.notify.error({
          content: this.$t('CourseStart.errors.assignmentRun'),
        });
      }
    },

    /**
     * Переход на страницу запущенной программы
     * @param {number} trackSessionId - id запущенной сессии прохождения программы
     */
    async goToAssignmentTrack(trackSessionId: number) {
      await this.$router.replace({
        name: this.Names.R_APP_LEARNING_TRACK,
        params: {
          trackSessionId: String(trackSessionId),
        },
      });
    },
  },
});
